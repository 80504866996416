<template>
  <div :style="slidePosition" class="z-4 w-100 h-100">
    <div class="position-absolute d-flex flex-column align-items-start justify-content-center"
      style=" width: 55vw; height: 100%; top:0%; left:15%; transform: translate(0%, 0%);">
      <div class="w-100 h-100 d-flex flex-column justify-content-center " style="padding: 2%;background-color: #8E87A4;">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div class="w-60" style="padding: 2vw;background-color: #000;">
            <inline-svg src="images/Slide7/hour-slide-7.svg" fill="#EE584A" width="100%" aria-label="HOUR"></inline-svg>
          </div>
          <div class="w-35 d-flex flex-column  justify-content-between h-100">
            <div class="w-100 " style="padding: 1.5vw;background-color: #000;">
              <inline-svg src="images/Slide7/date.svg" fill="#EE584A" width="100%" aria-label="HOUR"></inline-svg>
            </div>
            <div class="d-flex justify-content-around align-items-center" style="margin-top: 1vh;">
              <div class="w-30 shadow-sm bg-danger" style="height: 3vh;"></div>
              <div class="w-30 shadow-sm bg-success" style="height: 3vh;"></div>
            </div>
          </div>
        </div>

        <div class="w-100 " style="margin-top: 3%;padding: 2vw; background-color: #000;">
          <h6 class="text-white font-vw-3 fade7" style="opacity:1; ">
            1974
          </h6>
          <h6 class="text-white font-vw-2-5 fade7" style="opacity:1; ">
            FIRST BEAM
          </h6>
          <p class="mb-0 mt-2 text-7 text-white font-vw-1-1" style="opacity:1; ">
            The 520 MeV cyclotron was designed to accelerate H- ions (hydrogen
            atoms with an extra electron) to 75% the speed of light, then strip
            them of their electrons to create beams of positively charged
            protons that drive the multitude of experiments at TRIUMF. At 3:30
            pm on December 15, 1974, Director Reg Richardson, who developed the
            concept for TRIUMF’s H- cyclotron, successfully coaxed H- ions
            through the machine and extracted the very first proton beam.
          </p>
        </div>
      </div>
      <div class="w-100 bg-warning" style="height:30%">
        <div class="w-70 mx-auto h-100 d-flex justify-content-center align-items-center" style="padding: 1vw;">
          <inline-svg src="images/sockets.svg" fill="black" style="height:100%;" aria-label="SOCKETS"></inline-svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    setTimeout(() => {
      this.startLoop()
    }, 100)
  },
  methods: {
    startLoop() {
      anime({
        targets: ".blink",
        opacity: [1, 0],
        direction: "alternate",
        loop: true,
        duration: 700,
        easing: "easeInOutExpo"
      })
    },
    slide7(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1000
      })

      animation
        .add({
          targets: ".fade7",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 3000 + 30 * i
        })
        .add({
          targets: ".text-7",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    }
  },
  watch: {
    innerPosition() {
      this.slide7(this.innerPosition)
    }
  }
}
</script>

<style></style>
